<template>
  <div>
    <!-- input Recherche -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Recherche</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Recherche"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
      <b-form-group v-if="user.role==='admin'">
        <b-button
          :to="{ name: 'delegation-add' }"
          variant="gradient-primary"
          class="ml-1 d-inline-block"
        >
          <feather-icon icon="PlusIcon" />
          <span class="ml-1">Ajouter délégation</span>
        </b-button>
      </b-form-group>
    </div>
    <div
      v-if="isLoading === true"
      class="text-center mb-2"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
    </div>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="delegations"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span v-if="props.column.field === 'is_active'">

          <div v-if="props.row.is_active === true">
            <b-badge
              class="badge-glow bg-success"
              pill
            >
              <feather-icon
                icon="CheckIcon"
                class="mr-25"
              />
              <span>Activé</span>
            </b-badge>
          </div>
          <div v-else>
            <b-badge
              class="badge-glow bg-danger"
              pill
            >
              <feather-icon
                icon="XIcon"
                class="mr-25"
              />
              <span>Désactivé</span>
            </b-badge>
          </div>
        </span>
        <span v-else-if="props.column.field === 'action'">
          <span>
            <router-link
              class="text-info"
              :to="{ name: 'delegation-edit',
                     params: { id: props.row.id} }"
            >  <feather-icon
              :id="`invoice-row-${props.row.id}-show-icon`"
              icon="EditIcon"
              class="cursor-pointer mr-1"
              size="16"
            /></router-link>

            <b-tooltip
              title="Modifier"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-edit-icon`"
            />

          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Affichage 1 sur            </span>
            <b-form-select
              v-model="pageLength"
              :options="['10','20','50']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap "> de {{ props.total }} résultat trouvé </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BPagination, BFormGroup, BFormInput, BFormSelect, BButton,
  BSpinner, BTooltip,

} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import storeAuth from '@/store/store'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BSpinner,
    BTooltip,
  },
  data() {
    return {
      isLoading: false,
      pageLength: 50,
      user: {},
      dir: false,
      columns: [
        {
          label: 'Delegation',
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Delegation',
          },
        },
        {
          label: 'Gouvernorat',
          field: 'governorate.name',
          filterOptions: {
            enabled: true,
            placeholder: 'Gouvernorat',
          },
        },
        {
          label: 'N° localities',
          field: 'active_localities_count',
          filterOptions: {
            enabled: true,
            placeholder: 'N° localities',
          },
        },
        {
          label: 'N° colis',
          field: 'num_orders',
          filterOptions: {
            enabled: true,
            placeholder: 'N° Colis',
          },
        },
        {
          label: 'Activation',
          field: 'is_active',
          filterOptions: {
            enabled: true,
            placeholder: 'Activation',
          },
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      delegations: [],
      searchTerm: '',
    }
  },
  created() {
    this.user = storeAuth.state.user
    this.getDelegations()
  },
  methods: {
    async getDelegations() {
      this.isLoading = true
      try {
        const { data } = await axios.get('/api/delegations/list/')
        this.delegations = data
        this.isLoading = false
      } catch (error) {
        this.showToast('danger', 'top-center', 'Network error: ')
      }
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
